import request from "@/utils/request";

// 分页获取人才招聘列表
export function queryJobList(query) {
  return request({
    url: "/job/list",
    method: "post",
    data: query,
  });
}
// 获取人才招聘分类
export function queryJobCategory() {
  return request({
    url: "/job/category",
    method: "get",
  });
}

// 获取树形结构产品分类
export function queryProductCategory(query) {
  return request({
    url: "/prod/category",
    method: "get",
  });
} 
// 分页获取产品列表
export function queryProductList(query) {
  return request({
    url: "/prod/list",
    method: "post",
    data: query,
  });
}
// 获取产品应用列表
export function queryApplicationList(query) {
  return request({
    url: "/prod/application",
    method: "get",
  });
}
