<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    layout: {
      type: String,
      default: 'prev, pager, next'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>

<style  lang="scss">

.pagination-container {
  // background: #fff;
  padding: 32px 16px;
  text-align: center;
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color:#41CFAD;
  border:0;
}
.el-pagination{
  li{
font-size:16px;
font-family:Source Han Sans CN;
font-weight:300;
color:rgba(255,255,255,1);
width: 48px;
height: 48px;
line-height: 48px;

  }
  
}
.el-pagination.is-background .el-pager li:not(.active):hover{
  color:#41CFAD;
  border:1px solid #41CFAD;
}

.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
border:1px solid #fff;
border-radius:0;
background-color: #fff;
font-family:Source Han Sans CN;
font-weight:300;
color:#19222F;
width: 48px;
height: 48px;
margin:0 15px;
}
}
  .el-pagination {
    .el-pager .active{
    background-color:#41CFAD;
  }
  }
.pagination-container.hidden {
  display: none;
}
</style>
