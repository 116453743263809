<template lang="">
  <div class="container product">
    <Banner :title="product"></Banner>
    <Title :title="product"></Title>
    <div class=" wei-container prod_container flex-row-top">
      <div class="category " v-if="categoryList">
        <el-menu
          :default-active="activeCategory"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          text-color="#AAAAAA"
          :unique-opened="true"
          active-text-color="#41cfad"
        >
          <!-- background-color="#F7F8FA" -->

          <el-submenu
            v-for="item in categoryList"
            :key="item.id"
            :index="item.id.toString()"
          >
            <template slot="title">
              <span @click="changeCategory(item)">{{ item.name }}</span>
            </template>
            <el-menu-item
              v-for="child in item.childrens"
              :key="child.id"
              :index="child.id.toString()"
              @click="changeCategory(item, child)"
            >
              <el-tooltip
               v-if="!child.disabled"
                :content="child.name"
                placement="top"
              >
                <span class="ellipsis-1" style="width:200px;display:block;">{{ child.name }}</span>
              </el-tooltip>
               <span v-else class="ellipsis-1" style="width:200px;display:block;">{{ child.name }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="product_container ">
        <div class="product flex1" v-if="productList">
          <div
            class="product_item"
            v-for="(item, index) in productList"
            :key="index"
          >
            <div
              class="img-box"
              @click="showPreviewer(imgUploadUrl + item.img)"
              @mouseenter="mouseEnter(imgUploadUrl + item.img)"
              @mouseleave="showShadow = false"
            >
              <img :src="imgUploadUrl + item.img" alt="" />
              <div
                v-show="(showImgUrl == imgUploadUrl + item.img)&&showShadow"
                class="shadow pointer"
                @click="showPreviewer(imgUploadUrl + item.img)"
              >
                <i class="el-icon-search"></i>
              </div>
            </div>
            <div class="info">
              <div class="title ellipsis-2">{{ item.title }}</div>
              <div class="pdf pointer">
                <a :href="imgUploadUrl + item.pdf" target="_blank" :download="imgUploadUrl + item.pdf">Specifications</a>
                <img src="../../assets/images/icon_pdf.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      class="pagination"
      v-show="total > 0"
      :total="total"
      :page.sync="params.pageNum"
      :limit.sync="params.pageSize"
      :autoScroll="false"
      @pagination="queryProductList"
    />
    <el-image-viewer
      v-if="showViewer"
      :on-close="closePreviewer"
      :url-list="[showImgUrl]"
    />
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import Title from '@/components/title'
import Banner from '@/components/Banner'
import { mapGetters, mapMutations } from 'vuex'
import { queryProductList, queryProductCategory } from '@/api/product'
import Pagination from '@/components/Pagination/index'
export default {
  components: { Pagination, Banner, Title, ElImageViewer },
  data() {
    return {
      product: {},
      categoryList: [],
      productList: [],
      activeCategory: '',
      total: 1,
      params: {
        pageNum: 1,
        pageSize: 12,
        title: '',
        firstCategoryId: '',
        secondCategoryId: ''
      },
      showViewer: false,
      showImgUrl: '',
      showShadow: false
    }
  },
  created() {
    this.product = this.$t('product')
    if (this.$route.query.keyword != undefined) {
      this.params.title = this.$route.query.keyword
    }
    if (this.$route.query.first != undefined) {
      this.params.firstCategoryId = this.$route.query.first
      this.activeCategory = this.$route.query.first
    }
    if (this.$route.query.second != undefined) {
      this.params.secondCategoryId = this.$route.query.second
      this.activeCategory = this.$route.query.second
    }
    this.getQueryData()
  },
  computed: {
    ...mapGetters(['headerSearchValue', 'headerSearchTime', 'imgUploadUrl'])
  },
  watch: {
    headerSearchTime(value) {
      this.params.title = this.headerSearchValue
      this.search()
    }
  },
  methods: {
    getQueryData() {
      queryProductCategory().then((res) => {
        res.data.forEach((item) => {
          item.childrens.forEach((child) => {
            if (this.$i18n.locale === 'en') {
              if (child.name.length > 24) {
                child.disabled = false
              }else{
                 child.disabled=true
              }
            } else {
              if (child.name.length > 12) {
                child.disabled = false
              }else{
                 child.disabled=true
              }
            }
          })
        })

        this.categoryList = res.data
        this.queryProductList()
      })
    },
    queryProductList() {
      queryProductList(this.params).then((res) => {
        this.productList = res.data.records
        this.total = res.data.total
      })
    },
    search() {
      this.params.pageNum = 1
      this.queryProductList()
    },
    changeCategory(item, child) {
      this.params = {
        ...this.params,
        firstCategoryId: item.id,
        secondCategoryId: child ? child.id : ''
      }
      this.search()
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    closePreviewer() {
      this.showViewer = false
    },
    showPreviewer(img) {
      this.showImgUrl = img
      this.showViewer = true
    },
    mouseEnter(img) {
      this.showImgUrl = img
      this.showShadow = true
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  padding: 0 0 200px 0;
  .wei-container {
    padding: 0;
  }
  .category {
    width: 240px;
    margin-right: 40px;
    .category_item {
      width: 100%;
      height: 50px;
      display: inline-block;
      line-height: 50px;
      background: #ffffff;
      text-align: center;
      font-size: 18px;

      &.active {
        background: #41cfad;
        color: #fff;
      }
    }
  }
  .product_container {
    text-align: left;

    .product_item {
      width: 242px;
      display: inline-block;
      background: #fff;
      margin-right: 13px;
      margin-bottom: 38px;
      vertical-align: top;
      text-align: center;
      &:nth-child(4) {
        margin-right: 0;
      }

      .img-box {
        width: 100%;
        height: 240px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .shadow {
          width: 100%;
          height: 100%;
          background: rgba($color: #000000, $alpha: 0.7);
          font-size: 14px;
          color: #ffffff;
          line-height: 240px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }
      .info {
        padding: 18px 12px;
        .title {
          font-size: 18px;
          margin: 0 0 14px 0;
        }
        .pdf {
          width: 157px;
          height: 30px;
          line-height: 30px;
          padding: 0 18px;
          background: #f8ebeb;
          border: 1px solid #f5bfb8;
          border-radius: 4px;
          font-size: 12px;
          color: #d81e06;
          margin: 0 auto;
          .name {
            max-width: 96px;
            display: inline-block;
          }
          img {
            width: 16px;
            height: 16px;
            margin-left: 6px;
            vertical-align: top;
            margin-top: 7px;
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 80px;
  }
}
</style>
<style lang="scss">
body {
  .product {
    .c-title {
      .cname {
        margin-top: 100px;
      }
    }
  }
  .el-menu {
    width: 240px !important;
    .el-submenu {
      margin-bottom: 2px;
      background-color: #eee;
      &.is-opened {
        background: #19222f !important;
        .el-submenu__title {
          background: #19222f !important;
          color: #fff !important;
          &:hover {
            background: #19222f !important;
            color: #fff;
          }
          i {
            color: #fff;
            top: 30%;
            &.el-icon-arrow-down:before {
              content: '-';
              font-size: 32px;
            }
          }
        }
        &:hover {
          background-color: #19222f !important ;
        }
      }
      .el-menu--inline {
        background-color: #eee;
      }
      .el-submenu__title {
        font-size: 18px;
        padding: 0 15px;
        text-align: left;
        span{
          display: block;
        }
        .el-submenu__icon-arrow {
          transform: none;
        }
        &:hover {
          background: #eeeeee !important;
        }
        i {
          color: #aaa;
          font-size: 18px;
          top: 40%;
          &.el-icon-arrow-down:before {
            content: '+';
            font-size: 26px;
          }
        }
      }
      .el-menu-item {
        font-size: 16px;
        &:hover {
          background: #eeeeee !important;
          color: #41cfad !important;
          .el-submenu__title {
            background: #19222f !important;
            color: #41cfad !important;
          }
        }
        .el-submenu__title {
          font-size: 18px;
          &:hover {
            background: #eeeeee !important;
          }
        }
      }
      .el-menu-item {
        padding: 0 20px;
        text-align: left;
        padding-left: 20px !important;
      }
    }
  }
  .el-image-viewer__wrapper {
    z-index: 200000000000 !important;
  }
  .el-icon-circle-close:before {
    color: #fff;
  }
  .shadow {
    .el-icon-search {
      font-size: 28px;
    }
  }
}
</style>
